import { AxiosError } from 'axios';

import { remoteServerAxios, proxyServerAxios } from 'src/api/axios';
import { GET_EC_PSYCHIC_CATEGORIES, GET_NOTIFICATIONS_FROM_PSYCHICS } from 'src/shared/api/apiNames';
import type { UserType, CustomerOfferQueryParams } from 'types/objectTypes';
import { SourcePlatforms } from 'common-chat-components/enums';

export const getStickyOfferData = (customerId: string) => remoteServerAxios
  .get(`offers/sticky/${customerId}`)
  .then((resp) => resp)
  .catch((e: AxiosError) => { throw e.response; });

export const getStickyOfferDataProxy = (customerId: string) => proxyServerAxios
  .get('sticky', { params: { customerId } })
  .then((resp) => resp.data)
  .catch((e: AxiosError) => { throw e.response; });

export const getReadingsCount = (
  customerId: string,
  lastVisitTime: string,
) => remoteServerAxios
  .get(`customers/${customerId}/readingcount/${lastVisitTime}`)
  .then((resp) => resp.data)
  .catch((e: AxiosError) => { throw e.response; });

export const getUpcominngReadings = (customerId: string) => remoteServerAxios
  .get(`customers/${customerId}/readings`, { params: { ReadingHistoryFilter: 'Upcoming,Request,Missed,Cancelled,Past' } })
  .then((resp) => resp.data)
  .catch((e: AxiosError) => { throw e.response; });

export const getReadingsCountProxy = (
  customerId: string,
  lastVisitTime: string,
) => proxyServerAxios
  .get('customers/readings/count', { params: { customerId, lastVisitTime } })
  .then((resp) => resp.data)
  .catch((e: AxiosError) => { throw e.response; });

export const getCustomerDetails = (
  customerId: string,
  queryParameters?: Record<string, any>,
) => remoteServerAxios
  .get(`customers/${customerId}/details`, { params: queryParameters })
  .then((resp) => resp.data)
  .catch((e: AxiosError) => { throw e.response; });

export const addToFavoriteProxy = (
  customerId: string,
  extId: string,
) => proxyServerAxios
  .post('psychics/favorites/add', { customerId, extId })
  .then((resp) => resp.data)
  .catch((e: AxiosError) => { throw e.response; });

export const removeFromFavoriteProxy = (
  customerId: string,
  extId: string,
) => proxyServerAxios
  .post('psychics/favorites/remove', { customerId, extId })
  .then((resp) => resp.data)
  .catch((e: AxiosError) => { throw e.response; });

export const addToFavorite = (customerId: string, extId: string, token: string) => remoteServerAxios
  .post(
    `mpsychics/${customerId}/favorite/add`,
    { CustId: customerId, ExtId: extId },
    { headers: { authorization: token } },

  )
  .then((resp) => resp.data)
  .catch((e: AxiosError) => { throw e.response; });

export const removeFromFavorite = (
  customerId: string,
  extId: string,
  token?: string,
  cookie?: string,
) => remoteServerAxios
  .post(
    `mpsychics/${customerId}/favorite/remove`,
    { CustId: customerId, ExtIds: extId },
    {
      headers: {
        authorization: token,
        ...(cookie ? { cookie } : {}),
      },
    },
  )
  .then((resp) => resp.data)
  .catch((e: AxiosError) => { throw e.response; });

export const getCurrentGeolocation = () => remoteServerAxios
  .get('json/reply/IPGeolocationCountry')
  .then((resp) => resp.data)
  .catch((e: AxiosError) => { throw e; });

export const getPsychicNotifications = (user: UserType) => remoteServerAxios
  .get(GET_NOTIFICATIONS_FROM_PSYCHICS, { params: { customerId: user.custId },
    headers: { Accept: 'application/json' } })
  .then((resp) => resp.data)
  .catch((e: AxiosError) => {
    throw e.response;
  });

export const getCustomerOffers = (params: CustomerOfferQueryParams) => remoteServerAxios
  .get(
    'custoffers',
    {
      params,
      headers: { 'content-type': 'application/json' },
    },
  )
  .then((resp) => resp.data)
  .catch((e: AxiosError) => { throw e.response; });

export const getPsychicsForEc = async (user: UserType, psychicsIds?: string) => {
  const url = GET_EC_PSYCHIC_CATEGORIES.replace('<id>', user.custId);

  return remoteServerAxios
    .post(url,
      { extids: psychicsIds },
      {
        headers: {
          Authorization: user.authToken,
        },
      })
    .then((resp) => resp.data)
    .catch((e: AxiosError) => {
      throw e.response;
    });
};

export const getRecentPhoneNumbers = (custId: string) => remoteServerAxios
  .get(`customers/${custId}/callbacks/recentnumbers`)
  .then((resp) => resp.data)
  .catch((e: AxiosError) => {
    throw e.response;
  });

export const verifyUSPhoneNumber = (number: string, token: string) => remoteServerAxios
  .get(
    'customers/phoneValidation',
    {
      params: { PhoneNumber: number },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },

    },
  )
  .then((resp) => resp.data)
  .catch((e: AxiosError) => {
    throw e.response;
  });

export const updatePhoneNumber = (payload: Record<string, any>, user: UserType) => remoteServerAxios
  .post(
    `/customers/${user.custId}/updateCallbackNumber`,
    payload,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.authToken,
      },
    },
  )
  .then((resp) => resp.data)
  .catch((e: AxiosError) => {
    throw e.response;
  });

export const requestOfflineMessage = (
  user: UserType,
  extId: number,
  number: string,
  sourcePlatform: SourcePlatforms,
) => remoteServerAxios
  .post('chat/prepareDmSms',
    {
      CustomerId: user.custId,
      ExtId: extId,
      sendSMSToPhoneNumber: number,
      sourcePlatform,
    },
    {
      headers: {
        Authorization: user.authToken,
      },
    })
  .then((resp) => resp.data)
  .catch((e: AxiosError) => {
    throw e.response;
  });

export const getCustomersFeedback = (user: UserType, extId: number) => remoteServerAxios
  .post('/GetFeedback', { CustId: user.customerId, ExtId: extId, authToken: user.authToken })
  .then((resp) => resp.data);

export const checkPaymentAutoReload = (
  user: UserType,
  extId: number,
  duration: number,
) => remoteServerAxios
  .post('customer/appointments/autoreloadpayment',
    {
      CustId: user.custId,
      ExtId: extId,
      Duration: duration,
    },
    {
      headers: {
        Authorization: user.authToken,
      },
    })
  .then((resp) => resp.data)
  .catch((e: AxiosError) => {
    throw e.response;
  });

export const callReserve = (payload: Record<string, any>, user: UserType) => remoteServerAxios
  .post(
    'chat/customers/callbacks/reserve',
    payload,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.authToken,
      },
    },
  )
  .then((resp) => resp.data)
  .catch((e: AxiosError) => {
    throw e.response;
  });
